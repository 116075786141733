import React, { useState, useEffect } from 'react';
import './styles/Login.css'; // Create this file for styles
import icona from '../assets/icona.png';
import { injectScript, removeScript } from '../utils/scriptInjectorManager';
import { toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toastify

const LoginForm = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        const savedLoginStatus = localStorage.getItem('isLoggedIn');
        return savedLoginStatus === 'true'; // Convert string to boolean
    });

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission
        if (!validateForm()) {
            return; // If validation fails, don't proceed with form submission
        }

        try {
            const response = await fetch('https://labellaitalia.family/api/LISTA_CLIENTI?select=ID_CLIENTE,NOME,CODICE_CLIENTE,EMAIL,CELLULARE&USERNAME=eq.' + username + '&PASSWORD=eq.' + password, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYm90X2FkbWluIn0.c5Ww2JyWjlEYMy2O1EsCMGx6DYM3usCVfIbYc0ZJrK8"
                }
            });

            if (!response.ok) {
                toast.error('Ungültige Zugangsdaten');
            }

            const data = await response.json();
            if (data.length > 0) {
                // Set reponse to localStorage and SessionStorage
                localStorage.setItem('login-token-username', response);
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('username', username)
                sessionStorage.setItem('username', username)
                localStorage.setItem('nome', data[0].NOME)
                sessionStorage.setItem('nome', data[0].NOME)
                sessionStorage.setItem('ruolo',data[0].RUOLO);
                setIsLoggedIn(true);
                toast.success("Login erfolgreich!"); // Success toast on successful login


            } else {
                toast.error('Login fehlgeschlagen. Überprüfen Sie Ihre Anmeldedaten'); // Error toast for login failure
            }

        } catch (error) {
            toast.error(error.message); // Set error message for UI display
        }
    };

    const validateForm = () => {
        if (!username || !password) {
            toast.error("Bitte füllen Sie alle Felder aus!"); // Validation check
            return false;
        }
        return true;
    };

    const SESSION_TIMEOUT = 30 * 60 * 1000; // TIMEOUT 30 Minutes

    const sessionTimeout = setTimeout(() => {
        // Action when session expires (e.g., logout or show a warning)
        sessionStorage.clear();
    }, SESSION_TIMEOUT);

    useEffect(() => {
        if (isLoggedIn) {
            // Inject scripts when the user logs in
            injectScript('https://cdn.botpress.cloud/webchat/v2.1/inject.js', 'inject_script');
            injectScript('https://mediafiles.botpress.cloud/a8438638-cd49-477d-a13b-235da0a1b25a/webchat/v2.1/config.js', 'inject_config');
        } else {
            clearTimeout(sessionTimeout);
            // Remove scripts when the user logs out
            removeScript('inject_script');
            removeScript('inject_config');
        }

        // Cleanup function to remove scripts when the component unmounts
        return () => {
            clearTimeout(sessionTimeout);
            removeScript('inject_script');
            removeScript('inject_config');
        };
    }, [isLoggedIn]);

    const openFeinkost = () => {
        window.open('https://www.labellaitalia.family/files/Unsere_Produkte.pdf', '_blank');
    }

    const openWine = () => {
        window.open('https://www.labellaitalia.family/files/Weinkeller.pdf', '_blank');
    }

    const handleLogout = () => {
        setIsLoggedIn(false);
        localStorage.clear();
        sessionStorage.removeItem('nome')
        removeScript('inject_config');
        removeScript('inject_script');
        window.location.reload();
    };

    return (
        isLoggedIn ? (
            <div className="login-form-container">
                <div>
                    <img src={icona} alt="" />
                </div>
                <div>
                    <h1>Wilkommen, <br></br>{localStorage.getItem('nome')}!</h1>
                </div>
                <div className='outer-form'>
                    <form onSubmit={handleLogout}>
                        <input 
                            className="products_btn"
                            type="button"
                            onClick={openFeinkost}
                            value="Feinkost"
                        />
                        <input
                            className="products_btn"
                            type="button"
                            onClick={openWine}
                            value="Weinkeller"
                        />
                        <button className="abmelden_btn">Abmelden</button>
                    </form>
                </div>
            </div>
        ) : (
            <div className="login-form-container">
                <div>
                    {!isLoggedIn && <img src={icona} alt="" />}
                </div>
                <div>
                    <h1>La Bella Italia <br></br>Family</h1>
                </div>
                <div className='outer-form'>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="Benutzername"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Kennwort"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button className='anmelden_btn' type="submit">Anmelden</button>
                    </form>
                </div>

            </div>
        )
    );
};

export default LoginForm;
