import React, { useState } from 'react';
import { removeScript } from './utils/scriptInjectorManager';
import './App.css';
import Login from './components/Login'
import ImageGrid from './components/ImageGrid'
import icona from './assets/icona.png';
import logout from './assets/logout.png'

const App = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const savedLoginStatus = localStorage.getItem('isLoggedIn');
    return savedLoginStatus === 'true'; // Convert string to boolean
  });

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.clear();
    sessionStorage.removeItem('nome')
    removeScript('inject_config');
    removeScript('inject_script');
    window.location.reload();
  };

  return (
    <div className="container" id="main_cont">
      <div className="column">
        <ImageGrid />
      </div>
      <div className="text-section-container">
        <Login />
      </div>
    </div>
  );
};

export default App;
