import React from 'react';
import './styles/ImageGrid.css'
import lunga from '../assets/lunga.jpeg'; // Update with the correct image path
import prosciutto from '../assets/prosciutto.jpg'; // Update with the correct image path
import peperoni from '../assets/peperoni.jpg'; // Update with the correct image path
import ristorante from '../assets/gruppo.jpeg'; // Update with the correct image path
import gruppo from '../assets/gruppo.jpg'; // Update with the correct image path



const ImageGrid = () => {
  return (
    <div className="image-grid">
      <div className="column column-1">
        <img src={lunga} alt="" />
      </div>
      <div className="column column-2">
        <img src={prosciutto} alt="" />
        <img src={ristorante} alt="" />
        <img src={peperoni} alt="" />
        <img src={gruppo} alt="" />
      </div>
    </div>
  );
}

export default ImageGrid;